<template>
  <f7-page name="activity" v-if="activityId" @page:beforein="loadActivity">
    <f7-navbar no-shadow back-link>
      <f7-nav-title>{{ activityList[activityId].name }}</f7-nav-title>
    </f7-navbar>
    <!--     
    <f7-toolbar bottom>
      <f7-link icon-material="image">Upload</f7-link>
      <f7-link icon-material="videocam">Upload video</f7-link>
    </f7-toolbar>-->
    <f7-block v-show="!started">
      <f7-block strong class="text-align-center">
        Get the team ready for the next activity. Instructions will appear after
        starting the activity and you have limited time to complete the task.
      </f7-block>
      <f7-block>
        <p v-if="description"><b>Location:</b> {{ description }}</p>
        <p v-if="maxTime"><b>Maximum time:</b> {{ maxTime }} min</p>
        <p v-if="maxScore"><b>Maximum points:</b> {{ maxScore }}</p>
      </f7-block>

      <f7-button large fill @click="startActivity()">Start</f7-button>
    </f7-block>

    <f7-swiper
      class="page-swiper swiper-1"
      pagination
      v-if="started && activityId == 1"
    >
      <f7-swiper-slide>
        <f7-block>
          <f7-block-title large>Instructions</f7-block-title>
          <p>
            We have listed 20 rare items that you and your team is going to find
            and take a snapshot of.
          </p>
          <p>Click the camera button next to each thing to upload a photo.</p>
          <p><b>Maximum time</b>: {{ maxTime }} min</p>
          <p><b>Maximum points</b>: {{ maxScore }}</p>
          <p>
            <i
              >Swipe to the right to review items and your teams progress for
              this activity.</i
            >
          </p>
        </f7-block>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block>
          <f7-block-title
            large
            v-text="
              (progressData ? Object.keys(progressData).length : 0) +
              ' items found'
            "
          ></f7-block-title>
          <f7-list media-list v-if="itemData">
            <f7-list-item
              v-for="(item, index) in itemData"
              :key="index"
              :title="item.name"
              :subtitle="item.description"
              :disabled="activity.progress.finished"
              :link="
                progressData && progressData[index + 1]
                  ? `/activity/` + activityId + `/item/` + (index + 1)
                  : '#'
              "
              @click="
                progressData && progressData[index + 1]
                  ? showItem(index + 1)
                  : uploadItem(index + 1)
              "
            >
              <f7-icon
                slot="after"
                material="check"
                color="green"
                v-if="progressData && progressData[index + 1]"
              ></f7-icon>
              <f7-icon slot="after" material="camera_alt" v-else></f7-icon>
              <img
                slot="media"
                :src="progressData[index + 1].url"
                v-if="progressData && progressData[index + 1]"
              />
              <!-- <img slot="media" :src="null" v-else /> -->
            </f7-list-item>
            <f7-block>
              <f7-button
                color="green"
                large
                fill
                @click="endActivity()"
                :disabled="finishDisabled"
                >Finish</f7-button
              >
            </f7-block>
          </f7-list>
        </f7-block>
        <f7-block v-if="!progressData" class="noitems">{{
          $t("activity.noitems")
        }}</f7-block>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-swiper
      class="page-swiper swiper-2"
      pagination
      v-if="started && activityId == 2"
    >
      <f7-swiper-slide>
        <f7-block>
          <f7-block-title large>Instructions</f7-block-title>
          <p>
            Take photos of as many company logos our company have as customers
            before the time is running out.
          </p>
          <p>
            The logo has to be of a product, a store sign or in a paper and the
            photo should not be taken of a screen.
          </p>
          <p>Use the + button at the bottom to upload your pictures.</p>
          <p><b>Maximum time</b>: {{ maxTime }} min</p>
          <p><b>Maximum points</b>: {{ maxScore }}</p>
          <p>
            <i>Swipe to the right to review your activity items.</i>
          </p>
        </f7-block>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block>
          <f7-block-title
            large
            v-text="
              (progressData ? Object.keys(progressData).length : 0) +
              ' logos found'
            "
          ></f7-block-title>
          <f7-list media-list v-if="progressData">
            <f7-list-item
              v-for="(item, index) in progressData"
              :key="index"
              :title="item.caption"
              :subtitle="fixDateTime(item.timestamp)"
              :link="`/activity/` + activityId + `/item/` + index"
            >
              <img slot="media" :src="item.url" />
            </f7-list-item>
            <f7-block>
              <f7-button
                color="green"
                large
                fill
                @click="endActivity()"
                :disabled="finishDisabled"
                >Finish</f7-button
              >
            </f7-block>
          </f7-list>
        </f7-block>
        <f7-block v-if="!progressData" class="noitems">
          No logos uploaded yet.<br />
          Use the + button in the lower right corner to upload
        </f7-block>
      </f7-swiper-slide>
    </f7-swiper>

    <f7-swiper
      class="page-swiper swiper-3"
      pagination
      v-if="started && activityId == 3"
    >
      <f7-swiper-slide>
        <f7-block>
          <f7-block-title large>Instructions</f7-block-title>
          <p>
            We have put together a list of questions for your team to answer
            within the timeframe.
          </p>
          <p>
            Your score will be calculated based on number of correct answers +
            remaining minutes.
          </p>

          <p><b>Maximum time</b>: {{ maxTime }} min</p>
          <p><b>Maximum points</b>: {{ maxScore }}</p>
          <p>
            <i>Swipe to the right to see the questions.</i>
          </p>
        </f7-block>
      </f7-swiper-slide>
      <f7-swiper-slide v-for="(item, index) in questionData" :key="index">
        <f7-block class="question"
          ><blockquote>{{ index + 1 }}.</blockquote>
          {{ item.question }}</f7-block
        >
        <f7-block class="audio swiper-no-swiping">
          <audio controls preload="auto" v-if="item.audio">
            <source :src="item.audio" type="audio/mpeg" />
          </audio>
        </f7-block>
        <f7-block v-if="item.video" inset class="video">
          <iframe
            id="player1"
            width="100%"
            :src="item.video"
            frameborder="0"
            allowfullscreen
            iframe-video
          ></iframe>
        </f7-block>
        <f7-block inset v-if="item && item.question">
          <f7-row class="alternatives">
            <f7-col
              width="100"
              v-for="(alt, key) in item.alternatives"
              :key="key"
            >
              <f7-button
                raised
                @click="setAnswer(index + 1, key, this)"
                :fill="progressData && progressData[index + 1] == key"
                :disabled="remainingTime == ''"
              >
                <b>{{ key }}:</b><br />
                {{ alt }}
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <f7-block-title large>Finish</f7-block-title>
          <f7-block strong class="question">
            When you have completed all questions, click the Finish button
            below.
          </f7-block>
          <f7-button color="green" large fill @click="endActivity()"
            >Finish</f7-button
          >
        </f7-block>
      </f7-swiper-slide>
      <f7-block v-if="!questionData" class="noitems">{{
        $t("activity.noquestions")
      }}</f7-block>
    </f7-swiper>
    <f7-block
      class="page-swiper swiper-4"
      pagination
      v-if="started && activityId == 4"
    >
      <f7-block>
        <f7-block-title large>Instructions</f7-block-title>
        <p>
          Rewrite the lyrics for "Last Christmas" and try to relate it to
          GetAccept in some way.
        </p>
        <p>
          <b>Maximum time</b>: {{ maxTime }} min<br />
          <b>Maximum points</b>: {{ maxScore }}
        </p>
        <audio class="audio" controls preload="auto" style="width: 100%">
          <source src="static/audio/activity-4.mp3" type="audio/mpeg" />
        </audio>
      </f7-block>
      <f7-list no-hairlines>
        <f7-list-input
          outline
          type="textarea"
          :value="progressData"
          @change="saveProgressData"
          resizable
          inputStyle="min-height: 174px;"
          :placeholder="activityDescription"
        >
        </f7-list-input>
      </f7-list>
      <f7-block style="margin-top: -18px">
        <f7-row>
          <f7-col>
            <f7-button large fill @click="saveProgressData()"> Save </f7-button>
          </f7-col>
          <f7-col>
            <f7-button
              :disabled="
                (progressData && progressData.length < 20) || !progressData
              "
              color="green"
              large
              fill
              @click="endActivity()"
            >
              Finish
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
    <f7-block
      class="page-swiper swiper-5"
      pagination
      v-if="started && activityId == 5"
    >
      <f7-block>
        <f7-block-title large>Instructions</f7-block-title>
        <p>
          Open the secret box in the office and you will find two puzzles.<br />
          Solve the puzzles in shortest time and take a photo of them. Your
          score will be based on the number of minutes left when finishing.
        </p>
        <p><b>Maximum time</b>: {{ maxTime }} min</p>
        <p><b>Maximum points</b>: {{ maxScore }}</p>
        <img
          v-if="progressData"
          :src="progressData.url"
          class="progress-image"
          @click="launchFinalFilePicker()"
        />
      </f7-block>
      <f7-block>
        <f7-button
          v-if="!progressData"
          label="Upload picture "
          @click="launchFinalFilePicker()"
        >
          <f7-icon material="camera_alt"></f7-icon>
          Upload picture
        </f7-button>
      </f7-block>
      <f7-block>
        <f7-button
          color="green"
          large
          fill
          @click="endActivity()"
          :disabled="!progressData"
        >
          Finish
        </f7-button>
      </f7-block>
    </f7-block>
    <f7-block
      class="page-swiper swiper-6"
      pagination
      v-if="started && activityId == 6"
    >
      <f7-block>
        <f7-block-title large>Instructions</f7-block-title>
        <p>Try to identify 8 different objects in the hidden box.</p>
        <p><b>Maximum time</b>: {{ maxTime }} min</p>
        <p><b>Maximum points</b>: {{ maxScore }}</p>
      </f7-block>
      <f7-list no-hairlines class="">
        <f7-list-input
          v-for="index in 8"
          :key="index"
          floating-label
          :label="index"
          type="text"
          :value="progressData ? progressData[index] : null"
          @change="saveProgressPart(index, $event.target.value)"
        >
        </f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button
          color="green"
          large
          fill
          @click="endActivity()"
          :disabled="!progressData || progressData.length < 8"
        >
          Finish
        </f7-button>
      </f7-block>
    </f7-block>
    <!-- <f7-button v-if="started && activityId == 3" @click="nextQuestion()">Next</f7-button> -->

    <!-- <f7-fab-backdrop slot="fixed"></f7-fab-backdrop> -->

    <f7-fab
      position="right-bottom"
      slot="fixed"
      v-if="started && activityId == 2"
    >
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>

      <f7-fab-buttons position="top">
        <f7-fab-button label="Add Logo" @click="launchFilePicker()" fab-close>
          <f7-icon material="camera_alt"></f7-icon>
        </f7-fab-button>

        <f7-fab-button
          label="Add Video"
          @click="recordVideo()"
          fab-close
          v-if="activityId == 22"
        >
          <f7-icon material="videocam"></f7-icon>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
    <input
      type="file"
      ref="pickfile"
      style="display: none"
      @change="onFilePicked"
    />

    <f7-fab
      position="center-bottom"
      slot="fixed"
      :text="remainingTime"
      color="red"
    >
      <f7-icon material="timer"></f7-icon>
    </f7-fab>
  </f7-page>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import moment from "moment";

export default {
  data() {
    return {
      interval: null,
      elapsedTime: "",
      remainingTime: "",
      progressDialog: null,
      itemUploadId: null,
      activityUpload: null,
      activityDescription:
        "[Chorus]\n Last Christmas, I gave you my heart\n But the very next day, you gave it away\n \n [Verse 1]\n Once bitten and twice shy\n ...",
    };
  },
  computed: {
    activity() {
      return this.activityList[this.activityId];
    },
    started() {
      return this.activityList[this.activityId].progress.started
        ? this.activityList[this.activityId].progress.started
        : false;
    },
    finished() {
      return this.activityList[this.activityId].progress.finished
        ? this.activityList[this.activityId].progress.finished
        : false;
    },
    description() {
      return this.activityList[this.activityId].description
        ? this.activityList[this.activityId].description
        : false;
    },
    maxTime() {
      return this.activityList[this.activityId].maxTime
        ? this.activityList[this.activityId].maxTime
        : false;
    },
    maxScore() {
      return this.activityList[this.activityId].maxScore
        ? this.activityList[this.activityId].maxScore
        : false;
    },
    itemData() {
      return this.activityList[this.activityId].items
        ? this.activityList[this.activityId].items.filter((i) =>
            i ? i.name : null
          )
        : null;
    },
    progressData() {
      return this.activityList && this.activityId
        ? this.activityList[this.activityId].progress.data
        : null;
    },
    calculateScore() {
      if ([1, 2].includes(this.activityId)) {
        return this.activityList &&
          this.activityId &&
          this.activityList[this.activityId].progress &&
          this.activityList[this.activityId].progress.data
          ? Object.keys(this.activityList[this.activityId].progress.data).length
          : 0;
      } else if ([3].includes(this.activityId)) {
        return this.activityList &&
          this.activityId &&
          this.activityList[this.activityId].progress &&
          this.activityList[this.activityId].progress.data
          ? this.activityList[this.activityId].questions.filter((i, index) => {
              return (
                i.answer ==
                this.activityList[this.activityId].progress.data[index]
              );
            }).length
          : 0;
      } else if ([4].includes(this.activityId)) {
        return this.activityList &&
          this.activityId &&
          this.activityList[this.activityId].progress &&
          this.activityList[this.activityId].progress.maxScore
          ? this.activityList[this.activityId].progress.maxScore
          : 0;
      } else if ([5, 6].includes(this.activityId)) {
        return this.activityList &&
          this.activityId &&
          this.activityList[this.activityId].progress &&
          this.activityList[this.activityId].progress.startTime
          ? moment
              .duration(
                -moment().diff(this.startTime + this.maxTime * 60 * 1000)
              )
              .minutes()
          : 0;
      } else {
        return 0;
      }
    },
    questionData() {
      return this.activityList[this.activityId].questions
        ? this.activityList[this.activityId].questions.filter((i) => i.question)
        : null;
    },
    startTime() {
      return this.activityList[this.activityId].progress.startTime
        ? this.activityList[this.activityId].progress.startTime
        : false;
    },
    finishDisabled() {
      return this.activityList[this.activityId].progress &&
        this.activityList[this.activityId].progress.data &&
        Object.keys(this.activityList[this.activityId].progress.data).length >=
          this.activity.maxItems
        ? false
        : true;
    },
    uploadProgress() {
      return this.$store.getters.uploadProgress;
    },
    activityList() {
      return this.$store.getters.activityList;
    },
    activityId() {
      return this.$store.getters.activityId;
    },
    gameId() {
      return this.$store.getters.gameId;
    },
    teamId() {
      return this.$store.getters.teamId;
    },
  },
  watch: {
    uploadProgress() {
      if (
        this.progressDialog &&
        typeof this.progressDialog.setProgress === "function" &&
        this.uploadProgress
      ) {
        this.progressDialog.setProgress(this.uploadProgress);
      }
    },
    activity() {
      if (this.startTime && !this.interval) {
        this.interval = setInterval(() => {
          this.updateTimer();
        }, 1000);
      }
      if (
        !this.finished &&
        this.activityList &&
        this.activityId &&
        this.activity.maxItems > 0 &&
        this.activityList[this.activityId] &&
        this.activityList[this.activityId].progress &&
        this.activityList[this.activityId].progress.data &&
        Object.keys(this.activityList[this.activityId].progress.data).length >=
          this.activity.maxItems
      ) {
        // if ([1,2].includes(this.activityId) && !this.activityList[this.activityId].progress.finished) {
        //   this.activityList[this.activityId].progress.finished = true;
        //   const app = this.$f7;
        //   app.dialog.confirm(
        //     "All tasks are done, do you want to finish?",
        //     () => {
        //       clearInterval(this.interval);
        //       this.$store.commit("setAlertMessage", "activity.finished");
        //       this.endActivity();
        //     },
        //     () => {},
        //     "Finish activity?"
        //   );
        // }
      }
    },
  },
  methods: {
    startActivity() {
      // Start team time
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("teams")
        .child(this.teamId)
        .child("startTime")
        .once("value", (snapshot) => {
          if (!snapshot.exists()) {
            firebase
              .database()
              .ref("/games")
              .child(this.gameId)
              .child("teams")
              .child(this.teamId)
              .update({
                startTime: firebase.database.ServerValue.TIMESTAMP,
              });
          }
        });

      // Start activity time
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("activities")
        .child(this.activityId)
        .child("progress")
        .child(this.teamId)
        .update({
          started: true,
          count: 0,
          startTime: firebase.database.ServerValue.TIMESTAMP,
        });

      // Start room timer
      if (this.activityList[this.activityId].maxTeams) {
        firebase
          .database()
          .ref("/games")
          .child(this.gameId)
          .child("activities")
          .child(this.activityId)
          .update({
            availableTime:
              moment()
                .add(this.activityList[this.activityId].maxTime, "minutes")
                .unix() * 1000,
          });
      }
    },
    endActivity() {
      // End team time
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("teams")
        .child(this.teamId)
        .update({
          endTime: firebase.database.ServerValue.TIMESTAMP,
        });

      // End activity time
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("activities")
        .child(this.activityId)
        .child("progress")
        .child(this.teamId)
        .update({
          finished: true,
          endTime: firebase.database.ServerValue.TIMESTAMP,
          score: this.calculateScore,
        })
        .then(() => {
          this.updateTeamScore();
          this.$f7router.back("/");
        });

      // End room timer
      if (this.activityList[this.activityId].maxTeams) {
        firebase
          .database()
          .ref("/games")
          .child(this.gameId)
          .child("activities")
          .child(this.activityId)
          .update({
            availableTime: 1,
          });
      }
    },
    updateTeamScore() {
      // Calculate / Estimate team score
      var teamScore = 0;
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("activities")
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            snapshot.val().forEach((activity, key) => {
              if (
                activity.progress &&
                activity.progress[this.teamId] &&
                activity.progress[this.teamId].score
              ) {
                teamScore += activity.progress[this.teamId].score;
              }
            });
            console.log("teamScore", teamScore);
            firebase
              .database()
              .ref("/games")
              .child(this.gameId)
              .child("teams")
              .child(this.teamId)
              .child("score")
              .set(teamScore);
          }
        });
    },
    updateTimer() {
      if (this.startTime) {
        // let timeElapsed = moment().diff(this.startTime)
        // this.elapsedTime = this.padTime(moment.duration(timeElapsed).minutes()) + ':' + this.padTime(moment.duration(timeElapsed).seconds())
        let timeRemaining = moment().diff(
          this.startTime + this.maxTime * 60 * 1000
        );
        if (timeRemaining < 0) {
          this.remainingTime =
            this.padTime(moment.duration(-timeRemaining).minutes()) +
            ":" +
            this.padTime(moment.duration(-timeRemaining).seconds());
        } else {
          if (!this.finished) {
            clearInterval(this.interval);
            this.$store.commit("setAlertMessage", "alert.timeisout");
            this.endActivity();
          }
          this.remainingTime = "";
        }
      }
    },
    padTime(time) {
      return (time < 10 ? "0" : "") + time;
    },
    fixDateTime(datetime) {
      return moment(datetime).format("LLL");
    },
    recordVideo() {
      if (true || typeof navigator.device == "undefined") {
        // this.$store.commit('setAlertMessage', 'alert.capturedevicenotsupported')
        this.launchFilePicker();
        return false;
      }
      navigator.device.capture.captureVideo(
        (mediaFiles) => {
          this.uploadFile("video", mediaFiles[0]);
        },
        (error) => {
          var msg = "An error occurred during capture: " + error.code;
          if (error.code == 20) {
            // this.$store.commit('setAlertMessage', 'alert.cameranotavailable')
            this.launchFilePicker();
          } else if (error.code == 20) {
            this.$store.commit("setAlertMessage", "alert.nothingwassaved");
          } else {
            this.$store.commit("setAlertMessage", msg);
          }
        },
        {
          limit: 1,
          duration: 30,
        }
      );
    },
    takePhoto() {
      const fileReader = new FileReader();
      if (true || typeof navigator.device == "undefined") {
        // this.$store.commit('setAlertMessage', 'alert.capturedevicenotsupported')
        this.launchFilePicker();
        return false;
      }
      navigator.device.capture.captureImage(
        (mediaFiles) => {
          let file = mediaFiles[0];
          if (file && file.size < 1024 * 1000 * 50) {
            //50 MB
            // fileReader.readAsDataURL(file)
            let type = file.type.indexOf("image") > -1 ? "image" : "video";
            // fileReader.readAsArrayBuffer(file)
            // fileReader.onload = () => {
            // let fileUrl = file.fullPath;
            // fileUrl = "file://" + fileUrl.substring(8);
            // console.log(fileUrl)

            // window.resolveLocalFileSystemURL(file.fullPath, function (fileEntry) {
            //   fileEntry.file(function (file) {
            //     var reader = new FileReader();
            //     reader.onloadend = function () {
            //       // This blob object can be saved to firebase
            //       // var blob = new Blob([new Uint8Array(this.result)], { type: "image/jpeg" });
            //       file.data = this.result
            //       this.uploadFile(type, file)
            //       sendToFirebase(blob);
            //     };
            //     reader.readAsArrayBuffer(file);
            //   });
            // }, function (error) {
            //   console.log(error);
            // });

            // console.log('window.resolveLocalFileSystemURL')
            // window.resolveLocalFileSystemURL(file.fullPath, function success(fileEntry) {

            //   // Do something with the FileEntry object, like write to it, upload it, etc.
            //   // writeFile(fileEntry, imgUri);
            //   console.log("got file: " + fileEntry.fullPath);
            // console.log('fetch file:///private'+file.fullPath)
            // fetch(file.localURL).then(function (response) {
            //   // return response.blob()
            //   console.log('response.blob')
            //   console.log(response)
            //   file.data = response.blob()
            //   // file.data = new Blob([new Uint8Array(this.result)], { type: file.type })
            //   this.uploadFile(type, file)
            // }).then(function (fileBlob) {
            //   // resolve({ file: fileBlob, extension: 'jpg' })
            //   console.log('fileBlob')
            //   file.data = fileBlob
            //   // file.data = new Blob([new Uint8Array(this.result)], { type: file.type })
            //   this.uploadFile(type, file)
            // })
            // console.log('fetch ' + file.localURL)
            // fetch(file.localURL).then(function (response) {
            //   // return response.blob()
            //   console.log('response.blob')
            //   console.log(response)
            // }).then(function (fileBlob) {
            //   console.log('fileBlob')
            //   console.log(fileBlob)

            // })
            // })
            // file.data = new Blob([this.result], { type: file.type })
            // fileReader.onloadend = function () {
            // file.data = fileReader.result
            // file.data = new Blob([new Uint8Array(this.result)], { type: file.type })
            // this.uploadFile(type, file)
          }

          // }
          // else {
          // this.$store.commit('setAlertMessage', 'alert.maximumfilesize')
          // }
          // this.uploadFile(type, file)
        },
        (error) => {
          var msg = "An error occurred during capture: " + error.code;
          if (error.code == 20) {
            if (Device.cordova) {
              this.$store.commit("setAlertMessage", "alert.cameranotavailable");
            } else {
              this.launchFilePicker();
            }
          } else if (error.code == 20) {
            this.$store.commit("setAlertMessage", "alert.nothingwassaved");
          } else {
            this.$store.commit("setAlertMessage", msg);
          }
        },
        {
          limit: 1,
        }
      );
    },
    launchFilePicker() {
      this.itemUploadId = null;
      this.activityUpload = null;
      this.$refs.pickfile.click();
    },
    launchFinalFilePicker() {
      this.itemUploadId = null;
      this.activityUpload = true;
      this.$refs.pickfile.click();
    },
    showItem(id) {
      console.log("Show " + id);
    },
    uploadItem(id) {
      console.log("Upload " + id);
      this.$f7.preloader.show();
      this.itemUploadId = id;
      this.$refs.pickfile.click();
      setTimeout(() => {
        this.$f7.preloader.hide();
      }, 500);
    },
    saveProgressData(e) {
      if (e && e.target.value) {
        firebase
          .database()
          .ref("/games")
          .child(this.gameId)
          .child("activities")
          .child(this.activityId)
          .child("progress")
          .child(this.teamId)
          .child("data")
          .set(e.target.value);
      }
    },
    saveProgressPart(index, val) {
      if (index && val) {
        firebase
          .database()
          .ref("/games")
          .child(this.gameId)
          .child("activities")
          .child(this.activityId)
          .child("progress")
          .child(this.teamId)
          .child("data")
          .child(index)
          .set(val);
      }
    },
    onFilePicked(event) {
      const fileReader = new FileReader();
      if (!event.target.files) {
        this.$store.commit("setAlertMessage", "alert.invalidfile");
        return false;
      }
      let file = event.target.files[0];
      if (file["size"] < 1024 * 1000 * 50) {
        //50 MB
        let type = file.type.indexOf("image") > -1 ? "image" : "video";
        fileReader.onloadend = (event) => {
          if (fileReader.error) {
            console.log(fileReader.error);
          } else {
            file.data = fileReader.result;
            this.uploadFile(type, file);
          }
        };
        fileReader.readAsDataURL(file);
      } else {
        this.$store.commit("setAlertMessage", "alert.maximumfilesize");
      }
    },
    uploadFile(type, file) {
      const app = this.$f7;
      this.showDeterminate();

      file.extension = this.getFileExtension(file.name);
      file.id = this.getUUID();
      file.filename = file.id + file.extension;
      let storageRef = firebase
        .storage()
        .ref(
          "games/" +
            this.gameId +
            "/activities/" +
            this.activityId +
            "/teams/" +
            this.teamId +
            "/" +
            type +
            "/" +
            file.filename
        );
      // let uploadTask = file.localURL ? storageRef.put(file.localURL) : storageRef.putString(file.data, 'data_url')
      let uploadTask = storageRef.putString(file.data, "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.$store.commit("setUploadProgress", progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.hideDeterminate();
            if (this.itemUploadId) {
              let caption =
                this.activityList[this.activityId].items[this.itemUploadId]
                  .name;
              firebase
                .database()
                .ref("/games")
                .child(this.gameId)
                .child("activities")
                .child(this.activityId)
                .child("progress")
                .child(this.teamId)
                .child("data")
                .child(this.itemUploadId)
                .set({
                  url: downloadURL,
                  filename: file.filename,
                  type: type,
                  caption: caption,
                  timestamp: firebase.database.ServerValue.TIMESTAMP,
                });
              firebase
                .database()
                .ref("/games")
                .child(this.gameId)
                .child("activities")
                .child(this.activityId)
                .child("progress")
                .child(this.teamId)
                .child("count")
                .set(firebase.database.ServerValue.increment(1));
            } else if (this.activityUpload) {
              firebase
                .database()
                .ref("/games")
                .child(this.gameId)
                .child("activities")
                .child(this.activityId)
                .child("progress")
                .child(this.teamId)
                .child("data")
                .set({
                  url: downloadURL,
                  filename: file.filename,
                  type: type,
                  timestamp: firebase.database.ServerValue.TIMESTAMP,
                });
              firebase
                .database()
                .ref("/games")
                .child(this.gameId)
                .child("activities")
                .child(this.activityId)
                .child("progress")
                .child(this.teamId)
                .child("count")
                .set(firebase.database.ServerValue.increment(1));
              this.activityUpload = null;
            } else {
              app.dialog.prompt(
                "Enter a title/company name",
                "Enter name",
                (caption) => {
                  firebase
                    .database()
                    .ref("/games")
                    .child(this.gameId)
                    .child("activities")
                    .child(this.activityId)
                    .child("progress")
                    .child(this.teamId)
                    .child("data")
                    .push({
                      url: downloadURL,
                      filename: file.filename,
                      type: type,
                      caption: caption,
                      timestamp: firebase.database.ServerValue.TIMESTAMP,
                    });
                  firebase
                    .database()
                    .ref("/games")
                    .child(this.gameId)
                    .child("activities")
                    .child(this.activityId)
                    .child("progress")
                    .child(this.teamId)
                    .child("count")
                    .set(firebase.database.ServerValue.increment(1));
                  app.swiper.get(".swiper-" + this.activityId).slideTo(1);
                }
              );
            }
          });
        }
      );
    },
    showDeterminate() {
      const app = this.$f7;
      if (this.determinateLoading) {
        return;
      }
      this.determinateLoading = true;
      this.progressDialog = app.dialog.progress(
        this.$t("alert.uploadingfile"),
        0
      );
    },
    hideDeterminate() {
      const app = this.$f7;
      if (this.determinateLoading) {
        this.progressDialog.close();
        this.determinateLoading = false;
      }
    },
    getUUID() {
      // http://www.ietf.org/rfc/rfc4122.txt
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    },
    getFileExtension(filename) {
      var extension = filename.match(/\.[0-9a-z]+$/i);
      if (extension && extension.length > 0) {
        extension = extension[0].toLowerCase();
        return extension;
      } else {
        this.$store.commit("setAlertMessage", "alert.invalidfile");
        return false;
      }
    },
    setAnswer(question, answer) {
      if (question) {
        firebase
          .database()
          .ref("/games")
          .child(this.gameId)
          .child("activities")
          .child(this.activityId)
          .child("progress")
          .child(this.teamId)
          .child("data")
          .child(question)
          .set(answer);
      }
    },
    nextQuestion() {
      const app = this.$f7;
      app.swiper.get(".swiper-" + this.activityId).slideNext();
    },
    loadActivity() {
      if (this.startTime && !this.interval) {
        this.interval = setInterval(() => {
          this.updateTimer();
        }, 1000);
      }
    },
    openVideo(videoUrl) {
      var myPhotoBrowserVideo = this.$f7.photoBrowser.create({
        photos: [
          '<video src="' +
            videoUrl +
            '" type="video/mp4" controls playsinline preload="auto" style="width:100%;"></video>',
        ],
        theme: "dark",
        type: "standalone",
      });
      myPhotoBrowserVideo.open();
    },
  },
  mounted() {},
  created() {},
};
</script>
