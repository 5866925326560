import Vue from 'vue'
import Vuex from 'vuex'

// Import firebase
import firebase from 'firebase/app'
import { f7 } from 'framework7-vue';
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
// import 'firebase/firestore'
import moment from 'moment'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
    },
    plugins: [createPersistedState()],
    state: {
        signedIn: true,
        signingIn: false,
        alertMessage: null,
        pushMessage: null,
        dialogMessage: null,
        // darkMode: null,
        // forceReload: false,
        gameId: null,
        teamId: null,
        teamName: null,
        activityId: null,

        // gameId: 'S0ingPP4gwKB8HumL2lU',
        // teamId: 'urfIZKqv1lIU22bhKCjQ',
        // teamloadMessagesName: 'Lag 1',

        // gameId: '1234',
        // teamId: '1',
        // teamName: 'Lag 1',

        teamList: null,
        activityList: null,
        itemData: null,
        messageList: [],
        uploadProgress: 0
    },
    getters: {
        signedIn: state => state.signedIn,
        singingIn: state => state.singingIn,
        alertMessage: state => state.alertMessage,
        push_message: state => state.push_message,
        dialogMessage: state => state.dialogMessage,
        teamId: state => state.teamId,
        teamList: state => state.teamList,
        gameId: state => state.gameId,
        activityId: state => state.activityId,
        teamName: state => state.teamName,
        activityList: state => state.activityList,
        itemData: state => state.itemData,
        messageList: state => state.messageList,
        uploadProgress: state => state.uploadProgress
    },
    mutations: {
        setSignedIn(state, payload) {
            state.signedIn = payload
        },
        setSigningIn(state, payload) {
            state.signingIn = payload
        },
        setGameId(state, payload) {
            state.gameId = payload
        },
        setActivityId(state, payload) {
            state.activityId = payload
        },
        setTeamId(state, payload) {
            state.teamId = payload
        },
        setTeamName(state, payload) {
            state.teamName = payload
        },
        setTeamList(state, payload) {
            state.teamList = payload
        },
        setActivityList(state, payload) {
            state.activityList = payload
        },
        setItemData(state, payload) {
            state.itemData = payload
        },
        setMessageList(state, payload) {
            state.messageList = payload
        },
        setAlertMessage(state, payload) {
            state.alertMessage = payload
        },
        setPushMessage(state, payload) {
            state.push_message = payload
        },
        setDialogMessage(state, payload) {
            state.dialogMessage = payload
        },
        setUploadProgress(state, payload) {
            state.uploadProgress = payload
        }
    },
    actions: {
        loadTeams({ commit, state } ) {
            if (!state.gameId){
                return false
            }
            firebase.database().ref('/games').child(state.gameId).child('teams').on('value', snapshot => {
                if (snapshot.exists()) {
                    var teamList = {}
                    snapshot.val().forEach((team, key) => {
                        team.id = key
                        teamList[key] = team
                        if (!team.score) {
                            team.score = 0
                        }
                    })
                    commit('setTeamList', teamList)
                }
                else {
                    commit('setAlertMessage', 'alert.couldnotloadteams')
                }  
            });
        },
        loadGame({ commit, dispatch, state }, gameCode) {
            console.log('loadGame')
            var promise = new Promise((resolve, reject) => {
                firebase.database().ref('/games').child(gameCode).once('value', snapshot => {
                    if (snapshot.exists()) {
                        let gameId = snapshot.key
                        f7.preloader.hide()
                        commit('setSigningIn', false)
                        commit('setDialogMessage', 'Logging in')
                        commit('setGameId', gameId)
                        firebase.auth().signInAnonymously().then(user => {
                            commit('setSignedIn', true)
                            resolve()
                            commit('setDialogMessage', '')
                            dispatch('loadTeams');
                        }).catch(function (err) {
                            // Handle Errors here.
                            console.log(err)

                            commit('setDialogMessage', '')
                            commit('setSigningIn', false)
                            if (err.message.indexOf('network error') > -1) {
                                commit('setAlertMessage', 'alert.networkerror')
                            }
                            else if (err.message.indexOf('no user') > -1) {
                                commit('setAlertMessage', 'alert.usernotfound')
                            }
                            else {
                                console.log(err.message)
                                commit('setAlertMessage', err.message)
                            }
                        })
                    }
                    else {
                        f7.preloader.hide()
                        commit('setSigningIn', false)
                        commit('setAlertMessage', 'alert.invalidgamecode')
                    }
                            
                       
                })
            })
            return promise
        },
        signIn({ commit, dispatch }, payload) {
            firebase.auth().signInAnonymously().then(user => {
                console.log(payload)
                if (payload.gameCode) {
                    dispatch('loadGame', payload.gameCode).then(()=>{
                        if (payload.teamId) {
                            commit('setTeamId', payload.teamId)
                            dispatch('loadTeamName', payload.teamId)
                        }        
                    })
                }
                else {
                    commit('setAlertMessage', 'alert.entergamecode');
                }
                
            }).catch(function (err) {
                // Handle Errors here.
                console.log(err)
                commit('setDialogMessage', '')
                if (err.message.indexOf('network error') > -1) {
                    commit('setAlertMessage', 'alert.networkerror')
                }
                else if (err.message.indexOf('no user') > -1) {
                    commit('setAlertMessage', 'alert.usernotfound')
                }
                else {
                    console.log(err.message)
                    commit('setAlertMessage', err.message)
                }
            })

        },
        // signIn2({ commit, dispatch }, payload) {
        //     if (payload.gamecode) {
        //         let gameQueryRef = firebase.firestore().collection("games").where("code", "==", payload.gamecode)
        //         gameQueryRef.get().then(function (games) {
        //             if (games.size > 0) {
        //                 let gameId = games.docs[0].id

        //                 let gameRef = firebase.firestore().collection("games").doc(gameId)
        //                 gameRef.collection('teams').orderBy("name").get().then(function (snapshot) {
        //                     var teamList = []
        //                     snapshot.forEach(function (doc) {
        //                         teamList.push({
        //                             id: doc.id,
        //                             name: doc.data().name
        //                         })
        //                     })
        //                     commit('setTeamList', teamList)
        //                 })
        //                 commit('setDialogMessage', 'Logging in')
        //                 firebase.auth().signInAnonymously().then(user => {
        //                     commit('setSignedIn', true)
        //                     commit('setGameId', gameId)
        //                     commit('setDialogMessage', '')
        //                 }).catch(function (err) {
        //                     // Handle Errors here.
        //                     console.log(err)
        //                     reject(err)
        //                     commit('setDialogMessage', '')
        //                     if (err.message.indexOf('network error') > -1) {
        //                         commit('setAlertMessage', 'alert.networkerror')
        //                     }
        //                     else if (err.message.indexOf('no user') > -1) {
        //                         commit('setAlertMessage', 'alert.usernotfound')
        //                     }
        //                     else {
        //                         console.log(err.message)
        //                         commit('setAlertMessage', err.message)
        //                     }
        //                 })
        //             }
        //             else {
        //                 commit('setAlertMessage', 'alert.invalidgamecode')
        //                 reject()
        //             }
        //         }).catch(function (err) {
        //             console.log(err.message)
        //             reject(err)
        //             commit('setAlertMessage', err.message)
        //         })
        //     }
        //     else {
        //         commit('setAlertMessage', 'alert.entergamecode');
        //     }
        // },
        // listTeams({ commit }, payload) {
        //     let teamQueryRef = firebase.firestore().collection("teams").where("gameId", "==", payload)
        //     teamQueryRef.get().then(function (snapshot) {
        //         if (snapshot.size > 0) {
        //             var teamList = []
        //             snapshot.forEach(function (doc) {
        //                 teamList.push({
        //                     id: doc.id,
        //                     name: doc.data().name
        //                 })
        //             })
        //             commit('setTeamList', teamList)
        //         }
        //     })
        // },

        selectTeam({ commit, dispatch }, payload) {
            commit('setTeamId', payload.teamId)
            commit('setTeamName', payload.teamName)
            dispatch('loadActivityList')
        },
        loadActivityList({ commit, state }) {
            console.log('loadActivityList')
            var activityList = {}
            firebase.database().ref('/games').child(state.gameId).child('activities').on('value', snapshot => {
                if (snapshot.exists()) {
                    snapshot.val().forEach((activity, key) => {
                        activity.id = key
                        if (activity.progress) {
                            if (typeof activity.progress[state.teamId] != 'undefined') {
                                activity.progress = activity.progress[state.teamId]
                            }
                        }
                        else {
                            activity.progress = {}
                        }
                        activityList[key] = activity
                    })
                    commit('setActivityList', activityList)
                }
            })
        },
        loadItem({ commit, state }, payload) {
            firebase.database().ref('/games').child(state.gameId).child('activities').child(state.activityId).child('progress').child(state.teamId).child('data').child(payload).once('value', snapshot => {
                if (snapshot.exists()) {
                    commit('setItemData', snapshot.val())
                }
            })
        },
        removeItem({ commit, state }, payload) {
            commit('setDialogMessage', 'activity.removingitem')
            var promise = new Promise((resolve, reject) => {
                console.log(state.itemData)
                let itemRef = firebase.storage().ref('games/' + state.gameId + '/activities/' + state.activityId + '/teams/' + state.teamId + '/' + state.itemData.type + '/' + state.itemData.filename)
                itemRef.delete().then(() => {
                    firebase.database().ref('/games').child(state.gameId).child('activities').child(state.activityId).child('progress').child(state.teamId).child('data').child(payload)
                        .remove().then(() => {
                            commit('setDialogMessage', null)
                            resolve(true)
                        })
                        .catch(err => {
                            commit('setDialogMessage', null)
                            reject(err)
                        })
                })
            })
            return promise
        },
        loadMessages({ commit, state }) {
            console.log('loadMessages')
            firebase.database().ref('/games').child(state.gameId).child('chat').on('value', snapshot => {
                var messages = []
                snapshot.forEach(childSnapshot => {
                    messages.push({
                        key: childSnapshot.key,
                        // type: childSnapshot.val().sentby == firebase.auth().currentUser.uid ? 'sent' : 'received',
                        type: childSnapshot.val().teamid == state.teamId ? 'sent' : 'received',
                        text: childSnapshot.val().text,
                        // name: childSnapshot.val().sentby == firebase.auth().currentUser.uid ? firebase.auth().currentUser.displayName : '',
                        name: childSnapshot.val().teamid == state.teamId ? state.teamName : childSnapshot.val().sentby,
                        time: moment(childSnapshot.val().timestamp).format('LT'),
                        date: moment(childSnapshot.val().timestamp).format('dddd Do MMMM')
                    })
                })
                var groupedmessages = messages.reduce(function (rv, x) {
                    (rv[x['date']] = rv[x['date']] || []).push(x)
                    return rv
                }, {})
                commit('setMessageList', groupedmessages)
            })
        },
        sendMessage({ state }, payload) {
            var promise = new Promise((resolve, reject) => {
                firebase.database().ref('/games').child(state.gameId).child('chat')
                    .push({
                        // sentby: firebase.auth().currentUser.uid,
                        teamid: state.teamId,
                        sentby: state.teamName,
                        text: payload.msg,
                        timestamp: firebase.database.ServerValue.TIMESTAMP
                    })
                    .then(() => {
                        resolve(true)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
            return promise
        },
        loadTeamName({ dispatch, commit, state }, teamId){
            firebase.database().ref('/games').child(state.gameId).child('teams').child(teamId).on('value', snapshot => {
                if (snapshot.exists()) {
                    if (snapshot.val().name){
                        commit('setTeamName', snapshot.val().name)
                        dispatch('loadActivityList')
                    }
                    else{
                        dispatch('enterTeamName')
                    }
                }
            })
        },
        enterTeamName({ commit, state }){
            f7.dialog.prompt('Enter your team name', 'Team Name', val => {
                firebase.database().ref('/games').child(state.gameId).child('teams').child(state.teamId).update({
                'name': val
                }).then(() => {
                    commit('setTeamName', val)
                })
            }, () => {

            }, this.teamName)
        },
        saveTeamName({ state, commit }, payload) {
            firebase.database().ref('/games').child(state.gameId).child('teams').child(state.teamId).update({
                'name': payload
            }).then(() => {
                commit('setTeamName', payload)
            })
        },
        resetTeamProgress({ state }) {
            firebase.database().ref('/games').child(state.gameId).child('activities').once('value', snapshot => {
                if (snapshot.exists()) {
                    snapshot.val().forEach((activity, key) => {
                        snapshot.ref.child(key).child('progress').child(state.teamId).remove()
                    })
                }
            })
            firebase.database().ref('/games').child(state.gameId).child('teams').child(state.teamId).update({
                'startTime': null,
                'endTime': null,
                'score': 0
            }).then(() => {

            })

        },
        // loadActivityList2({ commit, state }) {
        //     if (state.gameId) {
        //         let gameRef = firebase.firestore().collection("games").doc(state.gameId)
        //         let activityRef = gameRef.collection('activities').orderBy("order")
        //         activityRef.get().then(snapshot => {
        //             if (snapshot.size > 0) {
        //                 var activityList = {}
        //                 snapshot.docs.forEach(doc => {
        //                     let activity = doc.data()
        //                     activity.id = doc.id
        //                     activityList[doc.id] = activity
        //                 })
        //                 commit('setActivityList', activityList)
        //             }
        //         })
        //             .catch(err => {
        //                 console.log("Error getting activities: ", err);
        //             });
        //     }
        // },
        // loadActivityProgress({ commit, state }) {
        //     let gameRef = firebase.firestore().collection("games").doc(state.gameId)
        //     let progressRef = gameRef.collection('progress').doc(state.teamId)
        //     progressRef.get().then(snapshot => {
        //         if (snapshot.size > 0) {
        //             var progressList = {}
        //             snapshot.docs.forEach(doc => {
        //                 let progress = doc.data()
        //                 progress.id = doc.id
        //                 progressList[doc.id] = activity
        //             })
        //             commit('setActivityList', activityList)
        //         }
        //     })
        // },
        signOut({ commit, state }) {
            firebase.auth().signOut().then(() => {
                commit('setSignedIn', false)
                commit('setGameId', null)
                commit('setTeamId', null)
                commit('setAlertMessage', 'alert.youaresignedout');
                location.reload()
            })
        },
    }

})