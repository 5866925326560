const messages = {
  en: {
    login: {
      title: 'Login',
      selectteam: 'Select team',
      signin: 'Sign in',
      signout: 'Sign out',
      signinor: '-- or continue with --',
      signinwithgoogle: 'Sign in with Google',
      teamname: 'Your team name',
      gamecode: 'Game code',
      help1: 'Ask your operator for a game code',
      help2: 'Click "Sign In" to continue'
    },
    main:{
      welcome: 'Welcome',
      team: 'Team',
      activity: 'Activity',
      activities: 'Activities',
      chat: 'Chat',
      leaderboard: 'Leaderboard',
      settings: 'Settings',
      logout: 'Logout'
    },
    chat:{
      entermessage: 'Enter message',
      nomessages: 'No messages yet - be the first :)',
      istyping: 'typing...'
    },
    activity:{
      remove: 'Remove',
      removingitem: 'Removing item...',
      noitems: 'No items uploaded yet',
      noquestions: 'No questions left',
      finished: 'Acitivty completed! Well done'
    },
    alert: {
      welcome: 'Welcome!',
      entergamecode: 'Please enter a game code',
      invalidgamecode: 'Invalid game code',
      networkerror: 'Could not connect to Internet',
      databaseerror: 'Could not connect to database',
      couldnotloadteams: 'Could not load teams',
      usernotfound: 'That team name could not be found',
      youaresignedout: 'You are now signed out',
      loggingin: 'Logging in...',
      confirmsignout: 'Are you sure you want to signout?',
      capturedevicenotsupported: 'No camera is supported on the device',
      cameranotavailable: 'Camera is not available',
      nothingwassaved: 'Nothing was saved',
      uploadingfile: 'Uploading file...',
      maximumfilesize: 'Maximum filesize is 50 Mb',
      pleasewait: 'Please wait...',
      timeisout: 'Time is out for this activity',
    }
  }
}
export { messages }
