<template>
  <f7-page name="item">
    <f7-navbar back-link>
      <f7-nav-title></f7-nav-title>
    </f7-navbar>
    <f7-block v-if="itemData" class="item-wrapper">
      <f7-block-title large v-text="itemData.caption">{{itemData.type}}</f7-block-title>
      <img v-show="!loading" :src="itemData.url" :onload="stopLoading()" />
      <f7-skeleton-block class="skeleton-effect-fade" v-show="loading" style="width: 100%; height: 240px; border-radius: 10px;"></f7-skeleton-block>
    </f7-block>
    <f7-block>
      <f7-button fill large color="red" @click="removeItem()">{{$t('activity.remove')}}</f7-button>
    </f7-block>
  </f7-page>
</template>
    
<script>
export default {
  data() {
    return {
      itemId: null,
      loading: true
    }
  },
  computed: {
    itemData() {
      return this.$store.getters.itemData
    },

  },
  methods: {
    fixDateTime(datetime) {
      return moment(datetime).format('LLL')
    },
    loadItem() {
      this.$store.dispatch('loadItem', this.itemId).then(() => {

      })
    },
    stopLoading() {
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    removeItem() {
      this.$store.dispatch('removeItem', this.itemId).then((status) => {
        if (status) {
          this.$f7router.back()
        }
      })
    }
  },
  mounted() {
  },
  created() {
    this.itemId = this.$f7route.params.item
    // console.log(this.$f7route.params)
    // console.log(this.itemId)
    this.loadItem(this.itemId)
  }
}
</script>
