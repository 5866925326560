export const mixin = {
    computed: {
        alertMessage() {
            return this.$store.getters.alertMessage
        },
        pushMessage() {
            return this.$store.getters.pushMessage
        },
        dialogMessage() {
            return this.$store.getters.dialogMessage
        }
    },
    watch: {
        alertMessage(value) {
            const self = this
            var msg = value
            if (msg) {
                if (typeof msg.message != 'undefined') {
                    msg = msg.message
                }
                if (msg.indexOf(' ') == -1 && msg.indexOf('.') > -1) {
                    msg = self.$t(msg)
                }
            }
            // this.showToastBottom(msg)
            // this.showToastTop(msg)
            this.showToastCenter(msg)
            setTimeout(function () {
                self.$store.commit('setAlertMessage', '')
            }, 4000)
        },
        pushMessage(value) {
            const self = this
            var msg = value
            if (msg) {
                if (typeof msg.message != 'undefined') {
                    msg = msg.message
                }
                // console.log('push',msg)
                if (msg.indexOf('{') > -1 && msg.indexOf('}') > -1) { //aps json
                    let aps = JSON.parse(msg)
                    if (aps.alert['loc-key']) {
                        if (self.$t('push.' + aps.alert['loc-key'])){
                            msg = self.$t('push.' + aps.alert['loc-key'])
                        }
                        else{
                            msg = aps.alert['loc-key']
                        }
                    }
                    if (aps.alert['loc-args']) {
                        msg = msg.replace('%@', aps.alert['loc-args'][0])
                    }
                    if (aps.alert.title) {
                        msg = '<b>' + aps.alert.title + '</b><br>' + (msg ? msg : '')
                    }
                }
                else if (msg.indexOf(' ') == -1 && msg.indexOf('.') > -1 && self.$t(msg)) {
                    msg = self.$t(msg)
                }
            }
            // this.showToastBottom(msg)
            this.showToastTop(msg)
        },
        dialogMessage(value) {
            const self = this
            var msg = value
            if (msg) {
                if (typeof msg.message != 'undefined') {
                    msg = msg.message
                }
                if (msg.indexOf(' ') == -1 && msg.indexOf('.') > -1) {
                    msg = self.$t(msg)
                }
            }
            if (msg) {
                self.$f7.dialog.preloader(msg)
            }
            else {
                self.$f7.dialog.close()
            }
        }
    },
    methods: {
        showToastTop(text) {
            const self = this
            // Create toast
            if (!self.toastTop || self.toastTop.destroyed) {
                self.toastTop = self.$f7.toast.create({
                    text: text,
                    closeTimeout: 5000,
                    position: 'top',
                    destroyOnClose: true
                })
            }
            // Open it
            self.toastTop.open()
        },
        showToastCenter(text) {
            const self = this;
            // Create toast
            if (self.toastCenter) {
                self.toastCenter.close()
            }
            if (text) {
                self.toastCenter = self.$f7.toast.create({
                    text: text,
                    position: 'center',
                    closeTimeout: 3000,
                });
                // Open it
                self.toastCenter.open();
            }
        },
        showToastBottom(text) {
            const self = this
            // Create toast
            if (!self.toastBottom || self.toastBottom.destroyed) {
                self.toastBottom = self.$f7.toast.create({
                    text: text,
                    closeTimeout: 3000,
                    destroyOnClose: true
                })
            }
            // Open it
            self.toastBottom.open()
        }
    }
}