// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';
import store from './store';

// Import firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Import Translations
import { messages } from './translations'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});


// Vue config
Vue.config.devtools = false
Vue.config.productionTip = false

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  // Register App Component
  components: {
    app: App
  },
  i18n
});


firebase.auth().onAuthStateChanged(function (user) {
  // if (user) {
  //   store.commit('setSignedIn', true)
  // }
  // else{
  //   store.commit('setSignedIn', false)
  // }
})