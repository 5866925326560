<template>
  <f7-page name="home" :page-content="true">
    <!-- Top Navbar -->
    <!-- <f7-navbar :sliding="false"> -->
    <!-- <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
    </f7-nav-left>-->
    <!-- <f7-nav-title sliding>{{$t('main.welcome')}}</f7-nav-title> -->
    <!-- <f7-nav-right>
        <f7-link icon-material="settings" panel-open="right"></f7-link>
    </f7-nav-right>-->
    <!-- <f7-nav-title-large>{{$t('main.welcome')}}</f7-nav-title-large> -->
    <!-- </f7-navbar> -->

    <!-- <f7-toolbar bottom>
      <f7-link>Left Link</f7-link>
      <f7-link>Right Link</f7-link>
    </f7-toolbar>-->
    <f7-messagebar
      class="messagebar-bottom"
      :placeholder="$t('chat.entermessage')"
      :max-height="54"
      ref="messagebar"
      :class="showMessagebar ? '' : 'hidden'"
    >
      <f7-link
        icon-material="send"
        slot="inner-end"
        @click="sendMessage"
      ></f7-link>
    </f7-messagebar>

    <f7-toolbar tabbar no-shadow bottom>
      <f7-link
        tab-link="#activity"
        tab-link-active
        icon-material="beenhere"
        :text="$t('main.activities')"
      ></f7-link>
      <f7-link
        tab-link="#chat"
        icon-material="chat"
        :text="$t('main.chat')"
      ></f7-link>
      <f7-link
        tab-link="#leaderboard"
        icon-material="format_list_numbered"
        :text="$t('main.leaderboard')"
      ></f7-link>
      <f7-link
        tab-link="#settings"
        icon-material="settings"
        :text="$t('main.settings')"
      ></f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="activity" tab-active>
        <f7-block-title large>{{ $t("main.activities") }}</f7-block-title>
        <f7-block>
          <f7-list no-hairlines no-hairlines-between v-if="activityList">
            <f7-list-item
              v-for="(activity, index) in activityList"
              :key="index"
              :title="activity.name"
              :footer="activityFooter(activity)"
              :disabled="activityDisabled(activity)"
              link="#"
              @click="loadActivity(activity.id)"
            >
              <f7-badge
                slot="after"
                :color="activityColor(activity)"
                v-if="activity.progress.started"
                v-text="activityProgress(activity)"
              ></f7-badge>
              <f7-icon
                slot="media"
                material="check_circle"
                color="green"
                v-if="activity.progress.finished"
              ></f7-icon>
              <f7-icon
                slot="media"
                material="radio_button_unchecked"
                color="orange"
                v-else-if="activity.progress.started"
              ></f7-icon>
              <f7-icon
                slot="media"
                material="radio_button_unchecked"
                color="red"
                v-else
              ></f7-icon>
            </f7-list-item>

            <!-- <f7-list-item disabled title="Find the things">
              <f7-badge slot="after">20</f7-badge>
              <f7-icon slot="media" material="check"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/activity/1" title="Logo Hunter"></f7-list-item>
            <f7-list-item disabled link="/activity/2" title="Trash Collector"></f7-list-item>
            <f7-list-item disabled link="#" title="Puzzled"></f7-list-item>-->
          </f7-list>
        </f7-block>
        <!-- <f7-block>
          <f7-button large fill @click="loadActivity()" v-if="!currentActivityId">Start activity</f7-button>
          <f7-button large fill @click="loadActivity()" v-if="currentActivityId">Goto activity</f7-button>
        </f7-block>-->
      </f7-tab>

      <f7-tab id="chat" @tab:show="loadMessages" @tab:hide="hideMessages">
        <div class="floating-title">
          <f7-block-title large>{{ $t("main.chat") }}</f7-block-title>
        </div>
        <f7-block>
          <f7-messages ref="messages">
            <div
              class="messages"
              v-for="(messages, index) in messageList"
              :key="index"
            >
              <f7-messages-title>
                <b>{{ index }}</b>
              </f7-messages-title>
              <f7-message
                v-for="(message, i) in messages"
                :key="i"
                :type="message.type"
                :text-footer="message.time"
                :image="message.image"
                :name="message.name"
                :first="isFirstMessage(message, index)"
                :last="isLastMessage(message, index)"
                :tail="isTailMessage(message, index)"
              >
                <span
                  slot="text"
                  v-if="message.text"
                  v-html="message.text"
                ></span>
              </f7-message>
            </div>
            <f7-messages-title v-if="Object.keys(messageList).length === 0">
              <br>
              <br>
              <br>
              <br>
              {{ $t("chat.nomessages") }}
              <br>
              <br>
            </f7-messages-title>
          </f7-messages>
        </f7-block>
      </f7-tab>

      <f7-tab id="leaderboard">
        <f7-block-title large>{{ $t("main.leaderboard") }}</f7-block-title>
        <f7-block>
          <f7-list no-hairlines no-hairlines-between>
            <f7-list-item
              v-for="(team, index) in teamScore"
              :key="index"
              :title="index + 1 + `. ` + (team.name ? team.name : '-')"
              :after="`` + team.score + ``"
            ></f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>

      <f7-tab id="settings">
        <f7-block-title large>{{
          teamName ? teamName : "Settings"
        }}</f7-block-title>
        <f7-block>
          <f7-card>
            <f7-card-content>
              <f7-card-header>
                <f7-icon material="timer"></f7-icon>
                <f7-block style="width: 180px">Time elapsed</f7-block>
                <f7-block style="width: 100px; text-align: right">{{
                  teamElapsedTime
                }}</f7-block>
              </f7-card-header>
            </f7-card-content>
          </f7-card>

          <f7-card>
            <f7-card-content>
              <f7-card-header>
                <f7-icon material="flag"></f7-icon>
                <f7-block style="width: 180px">Points collected</f7-block>
                <f7-block style="width: 100px; text-align: right">{{
                  teamTotalScore
                }}</f7-block>
              </f7-card-header>
            </f7-card-content>
          </f7-card>

          <f7-block>
            <f7-list inset>
              <f7-list-button
                title="Set team name"
                @click="setTeamName()"
              ></f7-list-button>
              <f7-list-button
                title="Reset team game data"
                color="red"
                @click="resetTeamProgress()"
              ></f7-list-button>
            </f7-list>
          </f7-block>
          <f7-block>
            <f7-button large fill @click="logout()">{{
              $t("main.logout")
            }}</f7-button>
          </f7-block>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
var authFlag = true;
import firebase from "firebase/app";
import moment from "moment";
export default {
  data() {
    return {
      currentActivityId: null,
      showMessagebar: false,
      gameCode: "",
      tabSettings: false,
    };
  },
  computed: {
    gameId() {
      return this.$store.getters.gameId;
    },
    teamList() {
      return this.$store.getters.teamList;
    },
    messageList() {
      return this.$store.getters.messageList;
    },
    signedIn() {
      return this.$store.getters.signedIn;
    },
    teamScore() {
      if (!this.$store.getters.teamList) {
        return null;
      }
      let obj = Object.keys(this.$store.getters.teamList);
      let sorted = obj.sort((a, b) =>
        this.$store.getters.teamList[a].score <
        this.$store.getters.teamList[b].score
          ? 1
          : -1
      );
      sorted = sorted.slice(0, 10);
      let list = [];
      return sorted.reduce((a, v) => {
        list.push(this.$store.getters.teamList[v]);
        return list;
      }, {});
    },
    teamId() {
      return this.$store.getters.teamId;
    },
    teamName() {
      return this.$store.getters.teamName;
    },
    teamElapsedTime() {
      let startTime =
        this.$store.getters.teamList && this.$store.getters.teamId
          ? this.$store.getters.teamList[this.$store.getters.teamId].startTime
          : null;
      let endTime =
        this.$store.getters.teamList && this.$store.getters.teamId
          ? this.$store.getters.teamList[this.$store.getters.teamId].endTime
          : moment();
      let timeElapsed = moment(endTime).diff(startTime);
      let elapsed =
        this.padTime(moment.duration(timeElapsed).hours()) +
        ":" +
        this.padTime(moment.duration(timeElapsed).minutes()) +
        ":" +
        this.padTime(moment.duration(timeElapsed).seconds());
      return elapsed;
    },
    teamTotalScore() {
      return this.$store.getters.teamList && this.$store.getters.teamId
        ? this.$store.getters.teamList[this.$store.getters.teamId].score
        : null;
    },
    activityList() {
      return this.$store.getters.activityList
        ? this.$store.getters.activityList
        : null;
    },
  },
  watch: {},
  methods: {
    activityDisabled(activity) {
      if (activity.progress.finished) {
        return true;
      } else if (activity.progress.started && !activity.progress.finished) {
        return false;
      } else if (
        activity.maxTeams &&
        activity.availableTime &&
        moment(activity.availableTime).diff() > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    activityFooter(activity) {
      if (
        activity.progress.started &&
        !activity.progress.finished &&
        activity.availableTime
      ) {
        let timeUntil = moment(activity.availableTime).fromNow();
        return "Finish " + timeUntil;
      } else if (
        activity.maxTeams &&
        moment(activity.availableTime).diff() > 0
      ) {
        let timeUntil = moment(activity.availableTime).fromNow();
        return "Available in " + timeUntil;
      } else {
        return activity.description;
      }
    },
    activityProgress(activity) {
      let activityCount = activity.progress.data
        ? activity.maxItems > 0
          ? Object.keys(activity.progress.data).length
          : 0
        : 0;
      if (activityCount > activity.maxItems) {
        activityCount = activity.maxItems;
      }
      if (activity.progress.score) {
        return activity.progress.score;
      }
      return (
        activityCount + (activity.maxItems > 0 ? `/` + activity.maxItems : "")
      );
    },
    activityColor(activity) {
      if (activity.progress && activity.progress.score) {
        return 'green';
      } else if (
        activity.progress.data &&
        Object.keys(activity.progress.data).length == activity.maxItems
      ) {
        return "green";
      } else if (activity.progress.started) {
        return "orange";
      } else {
        return "red";
      }
    },
    loadActivity(activityId) {
      this.$store.commit("setActivityId", activityId);
      this.$f7router.navigate("/activity/" + activityId);
    },
    loadMessages() {
      // this.$store.dispatch("loadMessages").then(() => {
      //   setTimeout(()=>{
      // this.$$(".page .page-content")[0].scrollTop = this.$$(
      //   ".page .page-content"
      // )[0].scrollHeight;
      //   },50);
      this.loading_chat = false;
      this.$f7.preloader.hide();
      // });
      setTimeout(() => {
        this.showMessagebar = true;
      }, 200);
    },
    hideMessages() {
      this.showMessagebar = false;
    },
    isFirstMessage(message, index) {
      const previousMessage = this.messageList[index - 1];
      if (message.isTitle) return false;
      if (
        !previousMessage ||
        previousMessage.type !== message.type ||
        previousMessage.name !== message.name
      )
        return true;
      return false;
    },
    isLastMessage(message, index) {
      const nextMessage = this.messageList[index + 1];
      if (message.isTitle) {
        return false;
      }
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.name !== message.name
      ) {
        return true;
      }
      return false;
    },
    isTailMessage(message, index) {
      const nextMessage = this.messageList[index + 1];
      if (message.isTitle) {
        return false;
      }
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.name !== message.name
      ) {
        return true;
      }
      return false;
    },
    sendMessage() {
      const text = this.messagebar.getValue().replace(/\n/g, "<br>").trim();
      const messagesToSend = [];
      if (text.trim().length) {
        messagesToSend.push({
          text,
        });
      }
      if (messagesToSend.length === 0) {
        this.messagebar.focus();
        return;
      }
      this.attachments = [];
      this.messagebar.clear();
      if (text.length) {
        // this.messagebar.focus()
      }
      this.send_link_loading = true;
      this.$store
        .dispatch("sendMessage", {
          msg: text,
        })
        .then(() => {
          this.send_link_loading = false;
        });
      // this.messagebar.focus()
    },
    setTeamName() {
      const app = this.$f7;
      app.dialog.prompt(
        "Enter your new team name",
        this.teamName ? this.teamName : "Team Name",
        (val) => {
          this.$store.dispatch("saveTeamName", val);
        },
        () => {},
        "" //this.teamName
      );
    },
    resetTeamProgress() {
      const app = this.$f7;
      app.dialog.confirm(
        "This will reset your teams game progress and data",
        "Confirm reset",
        () => {
          this.$store.dispatch("resetTeamProgress");
        }
      );
    },
    padTime(time) {
      return (time < 10 ? "0" : "") + time;
    },
    logout() {
      this.$store.dispatch("signOut");
    },
  },
  mounted() {
    this.$f7ready(() => {
      this.messagebar = this.$refs.messagebar.f7Messagebar;
      this.messages = this.$refs.messages.f7Messages;
    });
    this.messagebar.on("focus", () => {
      setTimeout(() => {
        if (this.$$("#chat .messages").length > 0) {
          this.$$("#chat .messages").scrollTop =
            this.$$("#chat .messages").scrollHeight;
        }
      }, 50);
    });
    this.messagebar.on("blur", () => {
      setTimeout(() => {
        this.typingMessage = false;
      }, 400);
    });
    this.messagebar.on("change", (e) => {
      if (e.getValue().length > 0 && !this.typingMessage) {
        this.typingMessage = true;
      } else if (e.getValue().length == 0 && this.typingMessage) {
        this.typingMessage = false;
      }
    });

    this.$$(".panel-right").on("panel:open", function () {
      this.tabSettings = true;
    });
    this.$$(".panel-right").on("panel:close", function () {
      this.tabSettings = false;
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user && authFlag) {
        authFlag = false;
        this.$store.dispatch("loadTeams");
        this.$store.dispatch("loadActivityList");
        this.$store.dispatch("loadMessages");
      }
    });
  },
  created() {},
};
</script>

