<template>
  <f7-app :params="f7params">
    <!-- Left panel with cover effect-->
    <!-- <f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block>Left panel content goes here</f7-block>
      </f7-page>
    </f7-view>
    </f7-panel>-->

    <!-- Right panel with reveal effect-->
    <!-- <f7-panel right reveal>
      <f7-view>
        <f7-page>
          <f7-navbar no-shadow :title="teamName"></f7-navbar>
          <f7-card>
            <f7-card-content :padding="false">
              <f7-card-header>
                <f7-icon material="timer"></f7-icon>
                <f7-block>Time</f7-block>
                <f7-block>{{ teamTime }}</f7-block>
              </f7-card-header>
            </f7-card-content>
          </f7-card>

          <f7-card>
            <f7-card-content :padding="false">
              <f7-card-header>
                <f7-icon material="flag"></f7-icon>
                <f7-block>Score</f7-block>
                <f7-block>{{ teamScore }}</f7-block>
              </f7-card-header>
            </f7-card-content>
          </f7-card>

          <f7-block>
            <f7-button large @click="setTeamName()">Set team name</f7-button>
            <br />
            <br />
            <f7-button large fill @click="logout()">{{
              $t("main.logout")
            }}</f7-button>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-panel> -->

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/" v-if="signedIn"></f7-view>

    <f7-login-screen id="login-screen" :opened="!signedIn || !teamId">
      <f7-view>
        <f7-page login-screen v-if="!signedIn || !teamList">
          <f7-login-screen-title>{{ $t("login.title") }}</f7-login-screen-title>
          <f7-block>
            <f7-list>
              <f7-list-input
                outline
                size="5"
                :autofocus="true"
                accept="number"
                inputmode="numeric"
                type="number"
                pattern="\d*"
                autocomplete="off"
                name="loginGameCode"
                :placeholder="$t('login.gamecode')"
                :value="loginGameCode"
                @keypress.native.enter="login"
                @input="loginGameCode = $event.target.value"
              ></f7-list-input>
              <f7-block>
                <f7-button
                  large
                  fill
                  :disabled="signingIn"
                  :icon-f7="signingIn ? 'arrow_2_circlepath' : ''"
                  class="spin"
                  :text="$t('login.signin')"
                  @click="login"
                >
                </f7-button>
              </f7-block>
            </f7-list>
          </f7-block>
          <f7-list>
            <f7-block-footer>
              {{ $t("login.help1") }}
              <br />
              {{ $t("login.help2") }}
            </f7-block-footer>
          </f7-list>
          <div class="footer-credit">
            Made with <f7-icon material="favorite"></f7-icon> by
            <f7-link
              href="https://www.getaccept.com/?ref=thumbify"
              target="_system"
              external
              ><img
                src="static/images/ga-logo.svg"
                alt="GetAccept - Best all-in-one Sales Enablement platform for B2B sales"
            /></f7-link>
          </div>
        </f7-page>
        <f7-page login-screen v-if="signedIn && !teamId && teamList">
          <f7-login-screen-title>{{
            $t("login.selectteam")
          }}</f7-login-screen-title>
          <f7-block>
            <f7-list media-list>
              <f7-list-item
                v-for="(team, index) in teamList"
                :key="index"
                :title="team.name"
                link="#"
                @click="selectTeam(team.id, team.name)"
              ></f7-list-item>
            </f7-list>
            <f7-button
              :text="$t('login.signout')"
              @click="logout()"
            ></f7-button>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import cordovaApp from '../js/cordova-app.js';
import routes from '../js/routes.js';
import { mixin } from '../js/mixin'
import firebase from 'firebase/app';
import moment from 'moment'
import { f7 } from "framework7-vue";

var firebaseConfig = {
  apiKey: "AIzaSyAKeQxmNRJTNBjAfsajxeN8CCjkDpZwH2o",
  authDomain: "dotech-app.firebaseapp.com",
  databaseURL: "https://dotech-app.firebaseio.com",
  projectId: "dotech-app",
  storageBucket: "dotech-app.appspot.com",
  messagingSenderId: "550456028843",
  appId: "1:550456028843:web:2f3fea440758f72ca891ee"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default {
  mixins: [mixin],
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: 'app.dotech.app', // App bundle ID
        name: 'DoTech', // App name
        theme: 'md', // Automatic theme detection

        // App routes
        routes: routes,

        view: {
          pushState: true,
        },

        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
      loginGameCode: null,
    }
  },
  computed: {
    signedIn() {
      return this.$store.getters.signedIn
    },
    signingIn() {
      return this.$store.getters.signingIn
    },
    gameId() {
      return this.$store.getters.gameId
    },
    teamId() {
      return this.$store.getters.teamId
    },
    teamName() {
      return this.$store.getters.teamName
    },
    teamTime() {
      let startTime = this.$store.getters.teamList && this.$store.getters.teamId ? this.$store.getters.teamList[this.$store.getters.teamId].startTime : null
      let endTime = this.$store.getters.teamList && this.$store.getters.teamId ? this.$store.getters.teamList[this.$store.getters.teamId].endTime : moment()
      let timeElapsed = moment(endTime).diff(startTime)
      let elapsed = this.padTime(moment.duration(timeElapsed).hours()) + ':' + this.padTime(moment.duration(timeElapsed).minutes()) + ':' + this.padTime(moment.duration(timeElapsed).seconds())
      console.log(elapsed)
      return elapsed
    },
    teamScore() {
      return this.$store.getters.teamList && this.$store.getters.teamId ? this.$store.getters.teamList[this.$store.getters.teamId].score : null
    },
    teamList() {
      return this.$store.getters.teamList
    },
  },
  methods: {
    login() {
      var payload = {}
      if (this.loginGameCode && this.loginGameCode.length > 4){
        f7.preloader.show()
        payload.gameCode = this.loginGameCode.substring(0,4)
        payload.teamId = this.loginGameCode.substring(4)
      }
      else if (this.loginGameCode){
        // payload.gameCode = this.loginGameCode
        this.$store.commit('setAlertMessage', 'alert.invalidgamecode')
        return false
      }
      else{
        return false
      }
      this.$store.commit('setSigningIn', true)
      this.$store.dispatch('signIn', payload)
    },
    padTime(time) {
      return (time < 10 ? '0' : '') + time;
    },
    selectTeam(teamId, teamName) {
      var payload = {}
      payload.teamId = teamId
      payload.teamName = teamName
      this.$store.dispatch('selectTeam', payload)
    },
    logout() {
      this.$f7.panel.close('.panel-right', false)
      this.$store.dispatch('signOut')
    },
    setTeamName() {
      this.$store.dispatch('enterTeamname')
    }
  },
  mounted() {
    this.$f7ready((f7) => {
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here

    });
    //Autoload game
    let self = this
    // this.$store.dispatch('loadGame', '1234').then(() => {
    //   self.selectTeam('1', 'Lag 1')
    // })

  }
}
</script>