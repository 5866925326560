
import HomePage from '../pages/home.vue';
import ActivityPage from '../pages/activity.vue';
import ItemPage from '../pages/item.vue';
import AdminPage from '../pages/admin.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/activity/:id',
    component: ActivityPage,
    keepAlive: true,
  },
  {
    path: '/activity/:id/item/:item',
    component: ItemPage
  },
  {
    path: '/admin/:gameId',
    component: AdminPage
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
