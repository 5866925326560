<template>
  <f7-page name="admin">
    <f7-navbar>
      <f7-nav-title>Admin view for {{ gameId }}</f7-nav-title>
    </f7-navbar>
    <f7-row>
      <f7-col>
        <f7-list simple-list>
          <f7-list-item
            v-for="(activity, index) in activityList"
            :key="index"
            :title="activity.name"
          >
            <f7-list-item
              v-for="(progress, index) in activity.progress"
              :key="index"
            >
              <f7-badge
                v-if="progress"
                :color="
                  progress.finished
                    ? `green`
                    : progress.started
                    ? `orange`
                    : `red`
                "
                v-text="index"
              ></f7-badge>
            </f7-list-item>
            <f7-link @click="scrollToSection('#activity-' + index)"
              >Show</f7-link
            >
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <f7-list>
          <f7-list-item
            v-for="(team, index) in teamList"
            :key="index"
            :title="index + 1 + '. ' + (team.name ? team.name : '-')"
            :after="team.score ? team.score : '0'"
          >
            <!-- <f7-list-item
              v-for="(progress, index) in activity.progress"
              :key="index"
            >
              <f7-badge
                v-if="progress"
                :color="
                  progress.finished
                    ? `green`
                    : progress.started
                    ? `orange`
                    : `red`
                "
                v-text="index"
              ></f7-badge>
            </f7-list-item> -->
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-0"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <f7-block-title medium>{{ activityList[0].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[0] && activityList[0].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[0].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.data">
              <f7-col width="20">
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 1, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
              <f7-col width="80">
                <div
                  v-for="(data, index) in progress.data"
                  :key="index"
                  style="float:left;"
                >
                  <f7-link
                    v-if="data && data.url"
                    @click="openPhoto(data.url,data.caption)"
                  >
                    <img :src="data.url.replace(/\.(jpe?g|png|gif|bmp)/,'_200x200.$1')" style="height: 100px" />
                  </f7-link>
                </div>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-1"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <f7-block-title medium>{{ activityList[1].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[1] && activityList[1].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[1].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.data">
              <f7-col width="20">
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 2, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
              <f7-col width="80">
                <div
                  v-for="(data, index) in progress.data"
                  :key="index"
                  style="float:left;"
                >
                  <f7-link
                    v-if="data && data.url"
                    @click="openPhoto(data.url,data.caption)"
                  >
                    <img :src="data.url" style="height: 100px" />
                  </f7-link>
                </div>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-2"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <f7-block-title medium>{{ activityList[2].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[2] && activityList[2].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[2].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.data">
              <f7-col width="20">
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 3, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
              <f7-col width="80">
                <div
                  v-for="(data, index) in progress.data"
                  :key="index"
                  style="float:left;"
                >
                <span v-if="data">
                  {{index}}: {{data}},
                  &nbsp; &nbsp;
                </span>
                </div>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-3"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <div style="float: right; width: 50%; margin-top: 15px">
          <audio class="audio" controls preload="auto" style="width: 100%">
            <source src="static/audio/activity-4.mp3" type="audio/mpeg" />
          </audio>
        </div>
        <f7-block-title medium>{{ activityList[3].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[3] && activityList[3].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[3].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.data">
              <f7-col width="20">
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 4, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
              <f7-col width="80">
                <f7-list-input
                  v-if="progress && progress.data"
                  label="Content"
                  type="textarea"
                  readonly
                  :value="progress.data"
                ></f7-list-input>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-4"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <f7-block-title medium>{{ activityList[4].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[4] && activityList[4].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[4].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.finished">
              <f7-col>
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 5, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <hr />
    <a id="activity-5"></a>
    <f7-row v-if="activityList">
      <f7-col>
        <f7-block-title medium>{{ activityList[5].name }}</f7-block-title>
        <f7-list
          v-if="activityList && activityList[5] && activityList[5].progress"
          media-list
        >
          <f7-list-item
            v-for="(progress, index) in activityList[5].progress"
            :key="index"
            :title="teamName(index)"
            :footer="'Time: ' + teamElapsedTime(progress)"
          >
            <f7-row v-if="progress && progress.data">
              <f7-col>
                <f7-list-input
                  label="Score"
                  type="text"
                  @change="setActivityScore(index, 6, $event.target.value)"
                  :value="progress.score"
                ></f7-list-input>
              </f7-col>
              <f7-col
                v-for="(data, index) in progress.data"
                :key="index"
                v-text="data ? index + ': ' + data : ''"
              />
            </f7-row>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
    
<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { f7 } from "framework7-vue";
import moment from "moment";

export default {
  data() {
    return {
      gameId: null,
      gameData: null,
    };
  },
  computed: {
    activityList() {
      return this.gameData && this.gameData.activities
        ? this.gameData.activities.filter((i) => i.name)
        : null;
    },
    teamList() {
      return this.gameData && this.gameData.teams
        ? this.gameData.teams.filter((i) => i)
        : null;
    },
  },
  methods: {
    teamElapsedTime(progress) {
      if (progress) {
        let timeElapsed = moment(progress.endTime).diff(progress.startTime);
        let elapsed =
          this.padTime(moment.duration(timeElapsed).hours()) +
          ":" +
          this.padTime(moment.duration(timeElapsed).minutes()) +
          ":" +
          this.padTime(moment.duration(timeElapsed).seconds());
        return elapsed;
      } else {
        return 0;
      }
    },
    padTime(time) {
      return (time < 10 ? "0" : "") + time;
    },
    fixDateTime(datetime) {
      return moment(datetime).format("LLL");
    },
    teamName(teamId) {
      if (this.gameData && this.gameData.teams && this.gameData.teams[teamId]) {
        return teamId + ". " + this.gameData.teams[teamId].name;
      } else {
        return "Team " + teamId;
      }
    },
    openPhoto(url,caption) {
      var myPhotoBrowser = this.$f7.photoBrowser.create({
        photos: [{url:url,caption:caption}],
        theme: "dark",
        type: "popup",
      });
      myPhotoBrowser.open();
    },
    scrollToSection(elem) {
      const el = this.$$(elem);
      if (el && el[0]) {
        el[0].scrollIntoView({ behavior: "smooth" });
      }
    },
    loadGame(gameId) {
      firebase
        .database()
        .ref("/games")
        .child(gameId)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            this.gameData = snapshot.val();
            f7.preloader.hide();
          }
        });
    },
    setActivityScore(teamId, activityId, teamScore) {
      // this.activityList[activityId].progress.score = teamScore;
      // console.log('setActivityScore',[this.gameId,teamId, activityId, teamScore]);
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("activities")
        .child(activityId)
        .child("progress")
        .child(teamId)
        .child("score")
        .set(parseInt(teamScore));
      this.updateTeamScore(teamId);
    },
    updateTeamScore(teamId) {
      // Calculate / Estimate team score
      var teamScore = 0;
      firebase
        .database()
        .ref("/games")
        .child(this.gameId)
        .child("activities")
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            snapshot.val().forEach((activity, key) => {
              if (
                activity.progress &&
                activity.progress[teamId] &&
                activity.progress[teamId].score
              ) {
                teamScore += activity.progress[teamId].score;
              }
            });
            console.log("teamScore", teamScore);
            firebase
              .database()
              .ref("/games")
              .child(this.gameId)
              .child("teams")
              .child(teamId)
              .child("score")
              .set(teamScore);
          }
        });
    },
    stopLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    removeItem() {
      this.$store.dispatch("removeItem", this.itemId).then((status) => {
        if (status) {
          this.$f7router.back();
        }
      });
    },
  },
  mounted() {},
  created() {
    f7.preloader.show();
    this.gameId = this.$f7route.params.gameId;
    this.loadGame(this.gameId);
  },
};
</script>
